import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/main.css'

import { Row, Col, Button } from 'reactstrap'
import { connect } from 'react-redux'
import { history } from '../../index.js'
import Cookies from 'universal-cookie'
import { RemoveTableData } from './../../actions/feedMonitorDashboardAction'

const cookies = new Cookies()

class Sidebar extends Component {
  constructor() {
    super()
    this.logOut = this.logOut.bind(this)
    this.goBack = this.goBack.bind(this)
  }
  logOut() {
    console.log('LOGGED OUT')
    this.props.RemoveTableData()
    cookies.remove('at')
    history.push('/')
  }
  goBack() {
    console.log('GoBack!')
    window.history.back()
  }

  render() {
    console.log(
      '======================== PROPS IN SIDEBAR :::: ',
      window.location.href,
      ' ========================================='
    )
    let url = window.location.href
    if (url.includes('/details')) {
      let ids = url.split('/details')[1]
      let specIds = ids.split('/')
      console.log(':::Details ::: ', ids.split('/'))
      var crs_pg_id = specIds[2]
      var location_id = specIds[3]
      var course_id = specIds[4]
      var assignment_id = specIds[5]
      var status = specIds[7]
    }
    if (url.includes('/coding-details')) {
      let ids = url.split('/coding-details')[1]
      let specIds = ids.split('/')
      console.log(':::Details ::: ', ids.split('/'))
      var crs_pg_id = specIds[2]
      var location_id = specIds[3]
      var course_id = specIds[4]
      var assignment_id = specIds[5]
      var status = specIds[7]
    }
    var at = cookies.get('isLoggedIn')
    var loggedIn = at !== undefined
    return (
      <div className="sidenav">
        <p className="companyname">
          {/* <a className='link' href="/">IMARTICUS</a> */}
          <Link to="/">
            <img
              src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
              alt="logo"
              className="as-dl-logo-hp"
            />
          </Link>
        </p>
        {window.location.href.includes('/details')&& (
          <p style={{ marginLeft: '40px', marginTop: '50px' }}>
            <Link
              style={{ fontSize: '18px', color: 'white' }}
              to={`/assignmentpanel?crs_pg_id=${crs_pg_id}&loc_id=${location_id}&course_id=${course_id}&ass_id=${assignment_id}&status=${status}`}
            >
              {' '}
              &lt;&lt; Back
            </Link>
          </p>
        )}
        {window.location.href.includes('/coding-details') && (
          <p
            style={{
              marginLeft: '16px',
              marginRight: '16px',
              marginTop: '50px',
              width: '95%',
              height: '44px',
            }}
          >
            <Link
              style={{ fontSize: '18px', color: 'white' }}
              to={`/assignmentpanel?crs_pg_id=${crs_pg_id}&loc_id=${location_id}&course_id=${course_id}&ass_id=${assignment_id}&status=${status}`}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  borderRadius: '8px',
                  background:
                    'linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #035642',
                  width: '100%',
                  height: '44px',
                }}
              >
                <img
                  style={{ marginLeft: '16px' }}
                  src="https://webcdn.imarticus.org/demo/ep_back.png"
                />{' '}
                <span
                  style={{
                    marginLeft: '8px',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '20px',
                  }}
                >
                  Back
                </span>
              </div>
            </Link>
          </p>
        )}
        {/* {alert(window.location.href)} */}
        {window.location.href.includes('/learners-attendance/') && 
          <p style={{ marginLeft: '40px', marginTop: '50px' }}>
            <Link
              style={{ fontSize: '18px', color: 'white' }}
              to={`/learners-attendance`}
            >
              {' '}
              &lt; Back
            </Link>
          </p> }
        {loggedIn ? (
          <div className="back-button-div">
            <Button
              className="back-button"
              color="danger"
              onClick={() => {
                this.logOut()
              }}
            >
              Log out
            </Button>
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  fmpanel: state.fmpanel,
  reducer: state.reducer,
})

export default connect(mapStateToProps, { RemoveTableData })(Sidebar)
