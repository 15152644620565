import React from 'react';
import { Switch, Route, Redirect, Link, NavLink } from 'react-router-dom';
import './ScheduleManagement.scss';
import GreaterThanImage from "./images/Shape.svg"
import Axios from 'axios';
import Cookies from "universal-cookie"
import { Autocomplete, TextField } from '@mui/material';
import { Button } from 'reactstrap';
import { makeStyles } from '@mui/styles'
import { Pagination } from 'semantic-ui-react';
import Calendar from "./images/calendar_white.svg"
import Back from "./images/chevron-up.svg"


const cookies = new Cookies()

const configs = require('../../config/configs.js')

const BASE_URL = configs.routes.BASE_URL_PRODUCTION
const BASE_URL_API = configs.routes.BASE_URL_API

let config = {
  headers: {
    'x-access-token': cookies.get('at'),
    'Content-Type': 'application/json',
  },
}

const ScheduleManagementSidebar = ({Permissions}) => {
  return (
    <div className="as-dl-sidebar">
      <Link to="/" className="as-dl-sidebar-lg link">
        <img
          src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
          className="as-dl-logo"
          alt="Logo"
        />
      </Link>
      <br/>
      <br/>
      <p style={{paddingLeft: "25px"}}><Link to="/" style={{color:'white'}}><img src={Back} style={{verticalAlign: "top", width: "20px"}} />Back</Link></p>
      <div style={{marginTop: "30px"}}>
        <ul>
          <li>
            <NavLink
              to="/schedule-management"
              className="nav-link"
              activeClassName="active-link"
              isActive={(match, location) => {   
                let lastLocation = location.pathname.split("/")
                let temp = lastLocation[lastLocation.length - 1]                                           // for styling two active links
                if (location.pathname === "/schedule-management" || temp.includes("Lectures")){
                    return true
                }
                if (!match) {
                  return false;
                }
              }}
            >
              Lecture Management
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/schedule-management/faculty"
              className="nav-link"
              activeClassName="active-link"
            >
              Faculty Management
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/schedule-management/classroom"
              className="nav-link"
              activeClassName="active-link"
            >
              Classroom Management
            </NavLink>
          </li>
          {Permissions && Object.keys(Permissions).includes('26') && Permissions['26'].includes(4) && // check for LGs
          <li>
            <NavLink
              to="/schedule-management/zoomIdAllocation"
              className="nav-link"
              activeClassName="active-link"
            >
              Zoom ID Allocation
            </NavLink>
          </li>
          }
        </ul>
      </div>
    </div>
  );
};

const FacultyDashboardSidebar = ({viewFacultyData, coursesArr, selectedCourse, batchesArr, selectedBatch, handleCourseChange, handleBatchChange, locationArr, handleLocations, selectedLocation, ifAttendance = false, resetFilters}) => {
  console.log({locationArr})
  const useStyles =  makeStyles({
    input: {
      color: 'white',
      border: 'white solid 1px'
    },
  })
  const classes = useStyles()
  return (
    <div className="as-dl-sidebar">
      <Link to="/" className="as-dl-sidebar-lg link">
        <img
          src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
          className="as-dl-logo"
          alt="Logo"
        />
      </Link>
      <br/>
      <br/>
      <p style={{paddingLeft: "25px"}}><Link to="/" style={{color:'white'}}><img style={{width: "20px", verticalAlign: "top"}} src={Back} /> Back</Link></p>
      <div style={{marginTop: "30px"}}>
        <ul className="changeMuiColor">
          <li style={{padding: "0 10px"}}>
            <Autocomplete
              options={coursesArr}
              getOptionLabel={option => option.name}
              value={selectedCourse}
              onChange={handleCourseChange}
              renderInput={params => <TextField style={{color: "white"}} required {...params} label="Select Course" />}
            />
          </li>
          <li style={{padding: "0 10px"}}>
            <Autocomplete
              style={{marginTop: "5px"}}
              options={locationArr}
              getOptionLabel={option => option.name.charAt(0).toUpperCase() + option.name.slice(1)}
              value={selectedLocation}
              onChange={handleLocations}
              renderInput={params => <TextField required {...params} label="Select Location" />}
            />
          </li>
          <li style={{padding: "0 10px"}}>
            <Autocomplete
              style={{marginTop: "5px"}}
              options={batchesArr}
              getOptionLabel={option => option.btch_name}
              value={selectedBatch}
              onChange={handleBatchChange}
              renderInput={params => <TextField required {...params} label="Select Batch" />}
            />
          </li>
          {ifAttendance == true ? "" : 
            <>
              <li>
                <Button
                  style={{marginLeft: "48px"}}
                  disabled={!selectedBatch || !selectedCourse}
                  color="success"
                  onClick={viewFacultyData}
                ><img src={Calendar} height="20px" width="20px" />&nbsp;&nbsp;View Calendar</Button>
              </li>
            </>
          }
              <li>
                <Button
                  style={{marginLeft: "68px"}}
                  disabled={!selectedBatch || !selectedCourse}
                  color="success"
                  onClick={resetFilters}
                >
                  Reset Filters
                </Button>
              </li>
        </ul>
      </div>
    </div>
  );
};

const LectureMetrics = ({ page, data, ifFaculty=false, ifLearner=false }) => {
  console.log("In LectureMatrics :: ", {page, data})
  return <>
    <div className="lectureMetricsDiv">
      {(page === "draft" || page == "published" || page == "completed" || page == "cancelled" || page == "upcoming") &&
        <div className="lectureMetricsCard">
          <p className="lectureMetricsP">Total Sessions Scheduled</p>
          <h3 className="green lectureMetricsH3">{data["totPublishedCount"] || data["totPublishedcount"] || "0"}</h3>
        </div>
      }
      {/* {(page === "draft" || page == "published" || page == "completed") &&
        <div className="lectureMetricsCard">
          <p className="lectureMetricsP">Extra Sessions Scheduled</p>
          <h3 className="green lectureMetricsH3">{data["totExtraSessionCount"] || "0"}</h3>
        </div>
      } */}
      {(page === "draft" || page == "published" || page == "completed" || page == "cancelled" || page == "upcoming") &&
        <div className="lectureMetricsCard">
          <p className="lectureMetricsP">Total Sessions Completed</p>
          <h3 className="green lectureMetricsH3">{data["totCompletedCount"] || "0" }</h3>
        </div>
      }
      {(page === "draft" || page == "published" || page == "completed" || page == "cancelled" || page == "upcoming") &&
        <div className="lectureMetricsCard">
          <p className="lectureMetricsP">Total Sessions Cancelled</p>
          <h3 className="redh3 lectureMetricsH3" style={{ color: "red" }}>{data["totCancelledCount"] || "0"}</h3>
        </div>
      }
      {(page == "completed") &&
      <>
        <div className="lectureMetricsCard">
          <p className="lectureMetricsP">Total Hours Completed</p>
          <h3 className="green lectureMetricsH3" style={{color: "green"}}>{convertToHours(data["totTimeCount"]?.total_done_time || 0, true) || "0"}</h3>
        </div>
        {/* 
        <div className="lectureMetricsCard">
          <p className="lectureMetricsP">Total Hours Scheduled</p>
          <h3 className="green lectureMetricsH3" style={{color: "green"}}>{convertToHours(data["totTimeCount"]?.total_time || 0, true) || "0"}</h3>
        </div> 
        */}
      </>
      }
      {/* {ifFaculty === true &&
        <div className="lectureMetricsCard" style={{minWidth: "184px"}}>
          <p className="lectureMetricsP">Total Payout</p>
          <h3 className="green lectureMetricsH3" style={{color: "green"}}>₹{data["totPayout"]?.toFixed(1) || 0}</h3>
        </div>
      } */}
      {ifLearner == true && 
      <div className="lectureMetricsCard" style={{minWidth: "184px"}}>
        <p className="lectureMetricsP">Total Student Present %</p>
        <h3 className="green lectureMetricsH3" style={{color: "green"}}>{data["totPresent"]?.toFixed(1) || 0}%</h3>
      </div>
      }
      {ifLearner == true && 
      <div className="lectureMetricsCard" style={{minWidth: "184px"}}>
        <p className="lectureMetricsP">Total Student Absent %</p>
        <h3 className="green lectureMetricsH3" style={{color: "green"}}>{data["totAbsent"]?.toFixed(1) || 0}%</h3>
      </div>
      }
    </div>
  </>
}
const NavigationBar = ({parentLink, ParentPage, CurrentPage}) => {
  if(!ParentPage){
    ParentPage = "Schedule Management"
  }
  return <>
      <div className='navigationBar'>
          <Link style={{color: "#055646", fontWeight: "600", textDecoration: "none"}} to={parentLink || "/schedule-management"} >{ParentPage}</Link> &nbsp;
          {CurrentPage && <>
              <img src={GreaterThanImage} style={{verticalAlign: "inherit"}} width={"6px"} />&nbsp; {CurrentPage}
            </>
          }
      </div>
  </>
}

const getAllLectures = async ({filteredCourse, filteredBatch, page, limit}, type = "draft") => {
  console.log("Inside API func getAllLectures!", { cid: filteredBatch._id, crsPgId: filteredBatch.crs_pg_id })
  let url = `${BASE_URL_API}cfpanelGeneric/gt_lectures?cid=${filteredBatch._id}&crsPgId=${filteredBatch.crs_pg_id}&type=${type}&page=${page}&limit=${limit}`
  let response = await Axios.get(url, config)
  if(response.data.success){
    return response.data.data
  } else {
    return false
  }
}

const searchLectures = async (filteredBatch, type = "draft", searchVal) => {
  console.log("Inside API func searchLectures!", { cid: filteredBatch._id, type })
  let url = `${BASE_URL_API}cfpanelGeneric/srch_lectures?cid=${filteredBatch._id}&type=${type}&search=${searchVal}`
  let response = await Axios.get(url, config)
  if(response.data.success){
    return response.data.data
  } else {
    return false
  }
}

const searchFaculties = async (searchVal) => {
  console.log("Inside API func searchFaculties!", {searchVal})
  let url = `${BASE_URL_API}cfpanelGeneric/srch_faculties?search=${searchVal}`
  let response = await Axios.get(url, config)
  if(response.data.success){
    return response.data.data
  } else {
    return false
  }
}
const publishLectures = async ({filteredCourse, filteredBatch, selectedRows}) => {
  console.log("Inside API func publishLectures!", { cid: filteredBatch._id, crsPgId: filteredBatch.crs_pg_id })
  let url = `${BASE_URL_API}cfpanelGeneric/pub_liv_lecs`
  let data = {
    selectedRows,
    cid: filteredBatch._id,
    crsPgId: filteredCourse._id
  }
  let response = await Axios.post(url, data, config)
  if(response.data.success){
    return response.data.data
  } else {
    return false
  }
}

const fetchLectureDetails =  async (batch, lec_id = '') => {
  console.log("Inside API func getAllLectures!", {batch})
  let toAddBatch = {}
  console.log(typeof batch, typeof batch === "string")
  if(typeof batch == "string"){
    toAddBatch["_id"] = batch
  } else {
    toAddBatch = batch
  }
  // if( !batch || !batch._id ){
  //   window.location.reload()
  // }
  let url = `${BASE_URL_API}cfpanelGeneric/gt_lec_details?cid=${toAddBatch._id}`
  if(lec_id){
    url += "&lec_id="+lec_id
  }
  try{
    let response = await Axios.get(url, config)
    console.log("fetchLectureDetails  ->  ", response)
    if(response.data.success){
      return response.data.data
    } else {
      console.log("fetchLectureDetails -> ", response)
      return false
    }
  } catch (err){
    console.log("fetchLectureDetails -> ", err)
  }
}

async function fetchCitiesAndLocations(){
  console.log("In fetchCitiesAndLocations!")
  try{
    let response = await Axios.get(`${BASE_URL_API}cfpanelGeneric/gt_al_locs`, config)
    console.log("gt_al_locs --> response.data", response.data)
    if(response.data.data){
      console.log("gt_al_locs --> response.data.data : ", response.data.data)
      return response.data.data
    }
    return false
  } catch (err){
    console.log("Error: ", err)
    return false
  }
}

const fetchCourseAndBatches = async () => {
  console.log("\n\n -- In fetchCourseAndBatches -- \n\n")
  try{
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    if (aid) query += 'aid=' + aid
    if (pid) query += '&pid=' + pid
    let url = `${BASE_URL_API}cfpanelGeneric/get_all_courses?${query}`
    let response = await Axios.get(url, config)
    console.log("Response in fetchCourseAndBatches ::: ", response)
    if(response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch (err){
    console.log("This is the error :: ", err)
  }
}

const createLiveLecture = async (toAddLecture) => {
  console.log("In createLiveLecture!")
  let URL = BASE_URL_API + 'cfpanelGeneric/ad_lv_lec'
  try{
    let response = await Axios.post(URL, toAddLecture, config)
    console.log("CreateLiveLecture -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return {
        status: false,
        msg: response.data.message
      }
    }
  } catch(err){
    console.log("Error in CreateLiveLecture : ", err)
    return false
  }
}

const fetchAllTopics = async (crspgId) => {
  console.log("In fetchAllTopics!")
  let URL = BASE_URL_API + 'cfpanelCourse/fetch_course_topics'
  try{
    let response = await Axios.post(URL, {crs_pg_id: crspgId, courseId: crspgId}, config)
    console.log("fetchAllTopics -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return {
        status: false,
        msg: response.data.message
      }
    }
  } catch(err){
    console.log("Error in fetchAllTopics : ", err)
    return false
  }
}

const deleteLiveLec = async liveLec => {
  console.log("\n In DeleteLiveLec with ", {liveLec},"\n")
  let URL = BASE_URL_API + 'cfpanelGeneric/dl_lv_lec'
  try{
    let response = await Axios.post(URL, {ll_id: liveLec._id}, config)
    console.log("DeleteLiveLecture -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in DeleteLiveLecture : ", err)
    return false
  }
}

const editLiveLec = async (liveLec, status="edit") => {
  console.log("\n In EditLiveLec with ", {liveLec},"\n")
  let URL = BASE_URL_API + 'cfpanelGeneric/ed_lv_lec'
  try{
    let response = await Axios.post(URL, {liveLec, status}, config)
    console.log("DeleteLiveLecture -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in DeleteLiveLecture : ", err)
    return false
  }
}
function calculateDifferenceInHours(time1, time2){
  if(time1 > time2){
    return convertToHours(time1-time2)
  } else {
    return convertToHours(time2-time1)
  }
}

function convertToHours(seconds, onlyHours = false) {
  console.log("In convertToHours :: ", seconds)
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  console.log("\n\n Check ::::: Hours and Minutes :: ", hours < 10, minutes < 10)
  if(hours < 10){
    hours = "0"+hours
  }
  if (minutes < 10){
    minutes = "0"+minutes
  }
  if(onlyHours === true){
    return `${hours}`
  }
  return `${hours}:${minutes}`
}
function epochToDate(epochTime){
  const date = new Date(epochTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}
function epochToTime(epochTime){
  const date = new Date(epochTime);
  
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let seconds = String(date.getSeconds()).padStart(2, '0');
  
  
  return `${hours}:${minutes}:${seconds}`;
}

const zoomSignature = (liv_lec_id, pid=cookies.get('pid')) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    let URL = BASE_URL_API + "courselib/gen_zm_sgn_v3"
    return Axios
      .post(
        URL,
        {
          liv_lec_id: liv_lec_id,
          // crs_id : $stateParams.cid,
          p_id: pid || cookies.get('pid'),
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

function addActualTimeToDate(ms, s) {
  // Convert the seconds to milliseconds
  const secondsInMs = s * 1000;

  // Add the seconds to the original date in ms
  const newMs = ms + secondsInMs;

  // Return the new date
  let date = new Date(newMs);
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let seconds = String(date.getSeconds()).padStart(2, '0');
  
  return `${hours}:${minutes}:${seconds}`;
}

const subtractOffset = (date, hours, minutes) => {
  // Convert hours and minutes to milliseconds
  const offsetMilliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000
  // Create a new date object with the offset subtracted
  const newDate = new Date(date.getTime() - offsetMilliseconds)
  return newDate
}


const markComplete = async function(toCompleteLecture, liveLec){
  console.log("\n In markComplete with ", {liveLec},"\n")
  let URL = BASE_URL_API + 'cfpanelGeneric/mrk_lec_cmplete'
  try{
    let response = await Axios.post(URL, {toCompleteLecture, liveLec}, config)
    console.log("markComplete -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in markComplete : ", err)
    return false
  }
}

const markCancel = async function(toCancelLecture, liveLec){
  console.log("\n In markComplete with ", {liveLec},"\n")
  let URL = BASE_URL_API + 'cfpanelGeneric/mrk_lec_cancel'
  try{
    let response = await Axios.post(URL, {toCancelLecture, liveLec}, config)
    console.log("markComplete -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in markComplete : ", err)
    return false
  }
}

const rescheduleLecture = async function(liveLec){
  console.log("\n In rescheduleLecture with ", {liveLec},"\n")
  let URL = BASE_URL_API + 'cfpanelGeneric/mrk_lec_cmplete'
  try{
    let response = await Axios.post(URL, {liveLec}, config)
    console.log("rescheduleLecture -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in rescheduleLecture : ", err)
    return false
  }
}

const getClassSchedule = async (cl_id, filters) => {
  console.log("\n In getClassSchedule with ", cl_id, filters, "\n")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_cl_schedule'
  try{
    let response = await Axios.post(URL, {cl_id, ...filters}, config)
    console.log("getClassSchedule -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in getClassSchedule : ", err)
    return false
  }
}

const getFacSchedule = async (fac_id, filters) => {
  console.log("\n In getFacSchedule with ", fac_id, filters, "\n")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_fac_schedule'
  try{
    let response = await Axios.post(URL, {fac_id, ...filters}, config)
    console.log("getFacSchedule -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in getFacSchedule : ", err)
    return false
  }
}

var getCoursesForRole = ({ applicationsOnly = false }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL_API + 'cfpanelGeneric/get_all_courses?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    if (applicationsOnly) query += '&applications_enabled=' + true
      Axios({
        method: 'get',
        url: URL + query,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
        },
      })
        .then((res) => {
          console.log({ data: res.data })
          statusCode = res.status
          if (res.data.success) {
            console.log('get_role_courses data!!', res.data)
            return resolve({ ...res.data.data, status: statusCode })
          } else {
            console.log('API call failed!', res.data)
            return resolve({ ...res.data, status: statusCode })
          }
        })
        .catch((err) => {
          console.log(err)
          return Promise.reject(err)
        })
  })
}
var fetchBatchesOfCourse = (idArr, locIdArr) => {
  return new Promise(async (resolve, reject) => {
    console.log({ idArr })
    let statusCode
    let URL = BASE_URL_API + 'cfpanelGeneric/get_course_batches?'
    let query = 'page=lecture'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += '&crs_pg_id_arr=' + JSON.stringify(idArr)
    if(locIdArr && locIdArr.length > 0) query += '&loc_ids=' + JSON.stringify(locIdArr)
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_course_batches data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return reject({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log("Error : ", err)
        return Promise.reject(err)
      })
  })
}

var getFacultyData = async ({page, from, to, cid, crsId}) => {
  console.log("\n In getFacultyData with \n")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_fac_data'
  try{
    let response = await Axios.post(URL, {page, from, to, cid, crsId}, config)
    console.log("getFacultyData -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in getFacultyData : ", err)
    return false
  }
}

var downloadInvoice = async ({page, from, to, cid, crsId}) => {
  console.log("\n In downloadInvoice with \n")
  let URL = BASE_URL_API + 'cfpanelGeneric/dwnld_fac_inv'
  try{
    let response = await Axios.post(URL, {cid, from, to, crsId}, config)
    console.log("downloadInvoice -> response", response, response.data)
    if (response.status === 200){
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log("url :: ", url)
      const link = document.createElement('a');
      console.log("link :: ", link)
      link.href = url;
      link.setAttribute('download', `Faculty_Schedule_Report.csv`);
      document.body.appendChild(link);
      link.click();
      // link.parentNode.removeChild(link);
      // return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in getFacultyData : ", err)
    return false
  }
}

var downloadReport = async ({data, setIfLoading, key, cid, filters}) => {
  console.log("\n In downloadReport with \n")
  let URL = BASE_URL_API + 'cfpanelGeneric/dwnld_report'
  try{
    let response = await Axios.post(URL, {data: JSON.stringify(data), key, cid, ...(filters && {content: filters.content.nm})}, config)
    console.log("downloadReport -> response", response, response.data)
    if (response.status === 200){
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log("url :: ", url)
      const link = document.createElement('a');
      console.log("link :: ", link)
      link.href = url;
      let name = response?.headers["content-disposition"]?.split('\"')[1] || ""
      console.log("\n\n\n\n",{name},"\n\n\n\n")
      link.setAttribute('download', name || 'content.csv');
      // link.setAttribute('download', `Invoice.csv`);
      document.body.appendChild(link);
      link.click();
      // link.parentNode.removeChild(link);
      // return response.data.data
      setIfLoading(false)
    } else {
      setIfLoading(false)
      return false
    }
  } catch(err){
    console.log("Error in getFacultyData : ", err)
    setIfLoading(false)
    return false
  }
}

let fetchAllChapters = async ({cid, crsPgId}) => {
  console.log("\n In fetchAllChapters with \n")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_ch_nd_lecs'
  try{
    let response = await Axios.post(URL, {cid, crsPgId}, config)
    console.log("fetchAllChapters -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in fetchAllChapters : ", err)
    return false
  }
}


const exportReport = async(page, filteredBatch) => {
  let URL = BASE_URL_API + 'cfpanelGeneric/exp_report'
  try{
    let response = await Axios.post(URL, {cid: filteredBatch._id, page}, config)
    console.log("downloadInvoice -> response", response, response.data)
    if (response.status === 200){
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log("url :: ", url)
      const link = document.createElement('a');
      console.log("link :: ", link)
      link.href = url;
      let name = response?.headers["content-disposition"]?.split('\"')[1] || ""
      console.log("\n\n\n\n",{name},"\n\n\n\n")
      link.setAttribute('download', name || 'content.csv');
      document.body.appendChild(link);
      link.click();
      // link.parentNode.removeChild(link);
      // return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in getFacultyData : ", err)
    return false
  }
}

let checkMyCoursePage = async crsPgId =>{
  let URL = BASE_URL_API + 'cfpanelGeneric/checkMyCoursePage'
  try{
    let response = await Axios.post(URL, {crsPgId}, config)
    console.log("checkMyCoursePage -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in getFacultyData : ", err)
    return false
  }
} 

let fetchAttendanceForLecture = async ({cid, crsPgId, chpId, lecId, searched}) => {
  console.log("\n In fetchAttendanceForLecture with \n")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_lrnrs_attndnce'
  try{
    let response = await Axios.post(URL, {cid, crsPgId, chpId, lecId, ...(searched ? {searched}: {})}, config)
    console.log("fetchAttendanceForLecture -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in fetchAttendanceForLecture : ", err)
    return false
  }
}

let fetchUsersAttendance = async ({cid, aid, filters}) => {
  console.log("\n In fetchAttendanceForLecture with \n")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_lrnrs_attndnce_user'
  try{
    let response = await Axios.post(URL, {cid, aid, myAid: aid, ...(filters && filters?.content ? {lecId: filters.content._id} : {})}, config)
    console.log("fetchAttendanceForLecture -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in fetchAttendanceForLecture : ", err)
    return false
  }
}

const fetchMetricsForLearners = async ({cid, crsPgId, lecId}) => {
  console.log("\n In fetchMetricsForLearners with \n")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_lrnrs_mtrcs'
  try{
    let response = await Axios.post(URL, {cid, crsPgId, lecId}, config)
    console.log("fetchMetricsForLearners -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in fetchMetricsForLearners : ", err)
    return false
  }
}

const markLearnerAttendance = async ({cid, lecId, selectedRows, whatToMark = ''}) => {
  console.log("\n In markLearnerAttendance with \n", {cid, lecId, selectedRows})
  let URL = BASE_URL_API + 'cfpanelGeneric/mrk_lrnrs_att'
  try{
    let response = await Axios.post(URL, {cid, lecId, selectedRows, whatToMark}, config)
    console.log("markLearnerAttendance -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in markLearnerAttendance : ", err)
    return false
  }
}

const PaginationMaker = ({ currentPage=1, clickOnPageHandler, totalPages=5 }) => {
  console.log("In Pagination :: ",{ currentPage, clickOnPageHandler, totalPages })
  return <Pagination 
    onPageChange={(e, data) => clickOnPageHandler(e, data)}
    defaultActivePage={0}
    totalPages={totalPages}
    activePage={currentPage}
  />
}

const submitBulkUploadAttendance = async ({lecId, file}) => {
  console.log("In submitBulkUploadAttendance ::: ", {lecId, file})
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('lecId', lecId);

    let statusCode
    let URL = BASE_URL + `/cfpanelGeneric/blk_upld_atttendance`
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: formData
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.status) {
          console.log('Data in blk_upld!!', res.data)
        } else {
          console.log('API call failed!', res.data)
        }
        return resolve(res)
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
const downloadSampleCSVAPIAttendance = ({cid, crspgid, lecId}) => {
  return new Promise((resolve, reject) => {
  
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/dwnld_smple_csv_attendance'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crspgid,
        cid,
        lecId
      },
      responseType: 'blob',
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data) {
          console.log('Data!!', res.data)
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `BulkUploadAttendance.csv`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          console.log('API call failed!', res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

const getCalendarData = async function({typeOf, id}) {
  console.log("\n\n\n In getCalendarData!")
  let URL = BASE_URL_API + 'cfpanelGeneric/gt_calendar_data'
  try{
    let response = await Axios.post(URL, {typeOf, id}, config)
    console.log("getCalendarData -> response: ", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in getCalendarData: ", err)
    return false
  }
}

let fetchLgData = async mail =>{
  let URL = BASE_URL_API + 'cfpanelGeneric/ftch_lg'
  try{
    let response = await Axios.post(URL, {mail}, config)
    console.log("fetchLgData -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return response.data.message
    }
  } catch(err){
    console.log("Error in fetchLgData : ", err)
    return "Something went wrong! Please try agin."
  }
} 

const downloadZoomIdAllocation = ({lgDetail}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/dwnld_zoomId_csv'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        ...lgDetail
      },
      responseType: 'blob',
    })
    .then((res) => {
      console.log({ data: res.data })
      statusCode = res.status
      if (res.data) {
        console.log('Data!!', res.data)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `BulkUploadZoomIdsAllocation.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.log('API call failed!', res.data)
      }
    })
    .catch((err) => {
      console.log(err)
      return reject(err)
    })
  })
}

const submitBulkUploadZoomIds = async data => {
  console.log("In submitBulkUploadZoomIds :: ", {data})
  let URL = BASE_URL_API + 'cfpanelGeneric/submt_blkupld_zoomids'
  try{
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('lg', JSON.stringify(data));

    config.headers['Accept'] = 'application/json'

    let response = await Axios.post(URL, formData, config)
    console.log("fetchLgData -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return response.data.message
    }
  } catch(err){
    console.log("Error in fetchLgData : ", err)
    return "Something went wrong! Please try agin."
  } 
}

const fetchAllLGsandZoomIds = async () => {
  console.log("\n\n\n In fetchAllLGsandZoomIds!")
  let URL = BASE_URL_API + 'cfpanelGeneric/ftch_lg_nd_zmids'
  try{
    let response = await Axios.post(URL, {}, config)
    console.log("fetchAllLGsandZoomIds -> response: ", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in fetchAllLGsandZoomIds: ", err)
    return false
  }
}

const deleteZoomIds = async (selectedRows, parentId) => {
  console.log("\n\n\n In fetchAllLGsandZoomIds!")
  let URL = BASE_URL_API + 'cfpanelGeneric/dl_zoom_ids'
  try{
    let response = await Axios.post(URL, {selectedRows, parentId}, config)
    console.log("fetchAllLGsandZoomIds -> response: ", response)
    if (response.data.success){
      return response.data.data
    } else {
      return false
    }
  } catch(err){
    console.log("Error in fetchAllLGsandZoomIds: ", err)
    return false
  }
}

const submitBulkUploadTopics = async data => {
  console.log("In submitBulkUploadTopics :: ", {data})
  let URL = BASE_URL + '/cfpanelGeneric/submt_blkupld_topics'
  try{
    console.log("\n\n\n\n\nIn uploading topics csv file : ", URL,"\n\n\n\n\n",{URL})
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('crs_pg_id', data.crs_pg_id);
    let config = {
      headers: {
        'x-access-token': cookies.get('at'),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    }    

    let response = await Axios.post(URL, formData, config)
    console.log("submitBulkUploadTopics -> response", response)
    if (response.data.success){
      return response.data.data
    } else {
      return response.data.message
    }
  } catch(err){
    console.log("Error in submitBulkUploadTopics : ", err)
    return "Something went wrong! Please try agin."
  } 
}

export {
  ScheduleManagementSidebar,
  NavigationBar,
  LectureMetrics,
  getAllLectures,
  fetchLectureDetails,
  fetchCitiesAndLocations,
  fetchCourseAndBatches,
  fetchBatchesOfCourse,
  createLiveLecture,
  publishLectures,
  deleteLiveLec,
  convertToHours,
  epochToDate,
  epochToTime,
  editLiveLec,
  markComplete,
  markCancel,
  rescheduleLecture,
  calculateDifferenceInHours,

  getClassSchedule,
  getFacSchedule,
  FacultyDashboardSidebar,
  getCoursesForRole,
  getFacultyData,
  downloadInvoice,
  
  fetchAllChapters,
  searchLectures,
  searchFaculties,
  exportReport,
  checkMyCoursePage,
  PaginationMaker,

  fetchAttendanceForLecture,
  fetchMetricsForLearners,
  markLearnerAttendance,
  fetchUsersAttendance,
  downloadReport,
  submitBulkUploadAttendance,
  downloadSampleCSVAPIAttendance,

  getCalendarData,
  addActualTimeToDate,
  zoomSignature,

  fetchLgData,
  downloadZoomIdAllocation,
  submitBulkUploadZoomIds,
  fetchAllLGsandZoomIds,
  deleteZoomIds,
  fetchAllTopics,

  submitBulkUploadTopics,
}
