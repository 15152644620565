import { useState } from "react";
import Cookies from "universal-cookie";
import { Link } from 'react-router-dom'
let cookies = new Cookies();

const TopBar2 = ({ heading }) => {
  const [drop, setDrop] = useState(false);

  return (
    <div className="topbar" style={{position: "fixed", width: "100%", top: 0, height: "60px", zIndex: "999"}}>
      <div className="topbar_info"></div>
      <div className="v_application_topbar">
        <Link to="/">
            <img
            src="https://cdn.pegasus.imarticus.org/partnerimarticus/logo1.svg"
            alt="logo"
            />
        </Link>
        
      </div>
      <div className="topbar-heading">
        <h5>{heading}</h5>
      </div>
    </div>
  );
};

export default TopBar2;