import {useState, useEffect, useRef} from "react";
import { FacultyDashboardSidebar, LectureMetrics, NavigationBar, convertToHours, downloadReport, epochToDate, epochToTime, fetchAllChapters, fetchAttendanceForLecture, fetchBatchesOfCourse, fetchCourseAndBatches, fetchMetricsForLearners, fetchUsersAttendance, markLearnerAttendance } from "../ScheduleManagement/ScheduleManagementHelper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingOverlay from "../ScheduleManagement/LoadingOverlay";
import {Popover, PopoverBody, Button} from 'reactstrap'
import Upload from "../ScheduleManagement/images/upload.svg"
import UpDown from "../ScheduleManagement/images/up-down.svg"
import MarkAttendanceModal from "./MarkAttendanceModal";
import ResponseInfoModal from "./ResponseInfoModal";
import { Link } from "react-router-dom";
import Export from "../ScheduleManagement/images/Export.svg"
import "../ScheduleManagement/ScheduleManagement.scss"

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const LearnerAttendanceDetail = (props) => {
  console.log("\nFrom props: ", {data: props.location.state, params: props.match.params}, "\n")
  let {cid, aid} = props.match.params
  const [ifLoading, setIfLoading] = useState(false)
  const [filters, setFilters] = useState({
    chapter: null,
    content: null
  })
  const [chapters, setChapters] = useState([])
  const [chapMap, setChapMap] = useState({})
  const [lectures, setLectures] = useState([])
  const profileName = useRef()
  const [attendanceData, setAttendanceData] = useState([])
  const [metricsData, setMetricsData] = useState({})

  // const [confirmationModalToggle, setConfirmationModalToggle] = useState(false)
  // const [reponseInfoModal, setReponseInfoModal] = useState(true)

  // const handleCourseChange = (index, newValue) => {
  //     console.log("In handleCourseChange!", index, newValue)
  //     if(!newValue) return
  //     fetchBatches([newValue._id]);
  //     setMainFiltersToApply({
  //         ...mainFiltersToApply,
  //         course: newValue
  //     })
  // };
  // let fetchBatches = async (idArr) => {
  //     console.log("Fetching relevant batches!")
  //     let { batches, centerLocations } = await fetchBatchesOfCourse(idArr)
  //     console.log("API call successful :: ", {batches, centerLocations}, typeof batches)
  //     setBatches(batches)
  //     setLocations(centerLocations)
  // }
  // const handleBatchChange = async (index, newValue) => {
  //     console.log("In handleBatchChange!", index, newValue)
  //     if(!newValue) return
  //     setMainFiltersToApply({
  //         ...mainFiltersToApply,
  //         batch: newValue
  //     })
  // };

  // const handleLocations = (e, newValue) => {
  //     console.log({newValue})
  //     if(newValue !== null){
  //         console.log("In the if check!")
  //         setMainFiltersToApply({ 
  //         ...mainFiltersToApply,
  //         location: newValue
  //         })
  //     } else {
  //         setMainFiltersToApply({
  //         location: null,
  //         batches: null
  //         })
  //     }
  // }  

  // useEffect(()=>{
  //     console.log({prevMainFilters, mainFiltersToApply})
  //     if((prevMainFilters && prevMainFilters.course && prevMainFilters.course?._id !== mainFiltersToApply.course?._id)  || (prevMainFilters == null && mainFiltersToApply.course != null)){
  //         console.log("Course is changed!")
  //         setMainFiltersToApply({
  //             ...mainFiltersToApply,
  //             location: null,
  //             batch: null
  //         })
  //     }
  //     if((prevMainFilters && prevMainFilters.location && prevMainFilters.location?._id !== mainFiltersToApply.location?._id) || (prevMainFilters == null && mainFiltersToApply.location != null)){
  //         console.log("Location is changed!")
  //         setMainFiltersToApply({
  //             ...mainFiltersToApply,
  //             batch: batches.filter(b => b.loc_id === mainFiltersToApply.location?._id)
  //         })
  //     }
  //     if(prevMainFilters && prevMainFilters.batch && prevMainFilters.batch?._id !== mainFiltersToApply.batch?._id  || (prevMainFilters == null && mainFiltersToApply.batch != null)){
  //         console.log("Batch changed")
  //     }
  //     console.log("Done with my changes!")
  //     if(mainFiltersToApply.course?._id && mainFiltersToApply.batch?._id){
  //         setIfLoading(true)
  //         fetchAllChapters({ cid: cid, crsPgId: crsPgId }).then(response => {
  //             console.log("fetch chapters -> Response :: ", response)
  //             if(response){
  //               setChapters(response.chapters)
  //               setChapMap(response.chapMap)
  //             }
  //             setIfLoading(false)
  //           })
  //           .catch(err => {
  //             console.log(err)
  //             alert("Something went wrong! Please try later.")
  //             setIfLoading(false)
  //           })
  //     }
  // }, [mainFiltersToApply.course, mainFiltersToApply.location, mainFiltersToApply.batch])

  useEffect(()=>{
    setIfLoading(true)
      fetchUsersAttendance({cid, aid}).then(response => {
          console.log("fetch Courses -> Response :: ", response)
          if(response){
            setAttendanceData(response.data)
            setChapters(response.chapters || [])
            setLectures(response.lectures || [])
            setChapMap(response.chapMap || {})
            setMetricsData({
              totPresent: response.totalPresent,
              totAbsent: response.totalAbsent
            })
            profileName.current = response.name
          }
          setIfLoading(false)
        })
        .catch(err => {
          console.log(err)
          setIfLoading(false)
        })
  },[])

  useEffect(() => {
      if(filters.chapter && filters.chapter._id && chapMap[filters.chapter._id]){
          setLectures(chapMap[filters.chapter._id])
      }
      if(filters.content && filters.chapter){
          setIfLoading(true)
          fetchUsersAttendance({cid, aid, filters}).then(response => {
            console.log("fetch Courses -> Response :: ", response)
            if(response){
              setAttendanceData(response.data)
              // setChapters(response.chapters || [])
              // setLectures(response.lectures || [])
              // setChapMap(response.chapMap || {})
              profileName.current = response.name
            }
            setIfLoading(false)
          })
          .catch(err => {
            console.log(err)
            setIfLoading(false)
          })
      }
  }, [filters.chapter, filters.content])

  const handleFilters = (e) => {
      console.log("In handlemainFiltersToApply :: ", e.target.name, e.target.value)
      setFilters({ 
          ...filters,
          [e.target.name]: e.target.value
      })
  }

  // const askMarkConfirmation = uid => {
  //     // if(selectedRows.length === 0){
  //     //     console.log("Hey in here")
  //     //     setSelectedRows(uid)
  //     // }
  //     setConfirmationModalToggle(!confirmationModalToggle)
  // }
  
  // const markAttendance = () => {
  //     setIfLoading(true)
  //     markLearnerAttendance({cid: cid, lecId: filters.content._id, selectedRows}).then(response => {
  //         setIfLoading(false)
  //         if(response){
  //             setSelectedRows([])
  //             setReponseInfoModal(true)
  //             setConfirmationModalToggle(false)
  //         } else {
  //             return alert("Something went wrong! Please try later!")
  //         }
  //     }).catch(err => {
  //         console.log("markLearnerAttendance error : ", err)
  //         setIfLoading(false)
  //     })
  // }

  console.log({filters})
  return (
    <>
      <div className="scheduleManagementSass">
        <div className="main-body">
          <LoadingOverlay isLoading={ifLoading} />
          <div className="container-fluid">
            <NavigationBar ParentPage={'Manage Learner Attendance'} parentLink={'/learners-attendance'} CurrentPage={profileName.current}  />
          </div>
          <div className="container">
            <FormControl style={{ minWidth: '450px' }} required>
              <InputLabel id="demo-simple-select-label-2">Chapter</InputLabel>
              <Select
                labelId="demo-simple-select-label-2"
                id="chapter"
                value={filters.chapter}
                label="Chapter"
                onChange={handleFilters}
                name="chapter"
              >
                {chapters.map((chapter) => (
                  <MenuItem
                    value={chapter}
                    selected={filters.chapter === chapter._id}
                  >
                    {capitalizeFirstLetter(chapter.nm)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: '450px', marginLeft: "16px" }} required>
              <InputLabel id="demo-simple-select-label-3">Content</InputLabel>
              <Select
                labelId="demo-simple-select-label-3"
                id="content"
                value={filters.content}
                label="Content"
                onChange={handleFilters}
                name="content"
                disabled={!(filters.chapter || filters.chapter == '--')}
              >
                {filters.chapter &&
                  lectures.map((l) => (
                    <MenuItem value={l}>{capitalizeFirstLetter(l.nm)}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <div className="row">
              {/* {filters.content && <ContentDetails filters={filters} />} */}
              <div className="col-5 learners__metrics" style={{ padding: '0px' }}>
                <LectureMetrics ifLearner={true} data={metricsData} />
              </div>
            </div>
            <div>
              <AttendanceDataPart
                attendanceData={attendanceData}
                setIfLoading={setIfLoading}
                filters={filters}
                // askMarkConfirmation={askMarkConfirmation}
                // selectedRows={selectedRows}
                // setSelectedRows={setSelectedRows}
                // handleCheckboxChange={handleCheckboxChange}
              />
            </div>
          </div>
          {/* {selectedRows.length > 0 && (
            <footer
              style={{
                position: 'fixed',
                bottom: '0px',
                width: 'calc(100% - 250px)',
                height: '80px',
                background: '#3C4852',
                boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
              }}
            >
              <div style={{ padding: '10px 40px' }}>
                <p style={{ marginTop: '20px', color: 'white' }}>
                  <b>
                    Selected {selectedRows.length}/{totalCount}
                  </b>
                  <button
                    className="btn btn-success"
                    style={{ float: 'right' }}
                    onClick={askMarkConfirmation}
                  >
                    Mark Present
                  </button>
                </p>
              </div>
            </footer>
          )} */}
        </div>
      </div>
    </>
  )
}
const ContentDetails = ({ filters }) => {
  let TYPE = {
    2: 'Online',
    3: 'Offline',
  }
  console.log(
    filters && filters?.content && filters && filters?.content.faculty,
    filters && filters?.content && filters && filters?.content.classroom
  )
  return (
    <div className="col-7" style={{ paddingLeft: '0px' }}>
      <div className="attendance__LectureDetails">
        <div className="row">
          <div className="col-6">
            Content Name:{' '}
            <span className="attendance__contentText">
              {filters.content.nm.length > 25
                ? filters.content.nm.substring(0, 25) + '...'
                : filters.content.nm}
            </span>
            <br />
            Date:{' '}
            <span className="attendance__contentText">
              {epochToDate(filters.content.stm)}
            </span>
            <br />
            Start Time:{' '}
            <span className="attendance__contentText">
              {epochToTime(filters.content.stm)}
            </span>{' '}
            &nbsp; End Time:{' '}
            <span className="attendance__contentText">
              {convertToHours(filters.content.tot_tim)}
            </span>
          </div>
          <div className="col-6">
            Mode:{' '}
            <span className="attendance__contentText">
              {TYPE[filters.content.type]}
            </span>
            <br />
            Trainer:{' '}
            <span className="attendance__contentText">
              {filters.content.faculty}
            </span>
            <br />
            Classroom:{' '}
            <span className="attendance__contentText">
              {filters.content.classroom}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const AttendanceDataPart = ({
  attendanceData,
  askMarkConfirmation,
  setIfLoading,
  filters
  // selectedRows,
  // setSelectedRows,
  // handleCheckboxChange,
}) => {
  const [popoverOpenIbutton, setIButtonPopoverOpen] = useState({
    status: false,
    msg: '',
    which: 1,
  })
  let msg1 =
    'Only chapters which are mark completed from "Schedule Management" will be reflected here.'
  let msg2 = 'Click on the Learner name to view the Content wise attendance.'
  return (
    <div>
      <h4
        style={{
          fontSize: '20px',
          fontWeight: '700',
          margin: '24px auto 0px auto',
        }}
      >
        Total Attendance &nbsp;&nbsp;
        {/* <span
          style={{ cursor: 'pointer', padding: '1px 11px' }}
          id={'popoverIButton1'}
          className={'myPopoverBtn'}
          onClick={(e) =>
            setIButtonPopoverOpen({ status: true, msg: msg1, which: 1 })
          }
        >
          i
        </span> */}
      </h4>
      <hr style={{ marginBottom: '14px' }} />
      {/* <Popover
        placement="bottom"
        target={'popoverIButton' + popoverOpenIbutton.which}
        isOpen={popoverOpenIbutton.status}
        toggle={(e) =>
          setIButtonPopoverOpen({ status: false, msg: '', which: 1 })
        }
      >
        <PopoverBody>{popoverOpenIbutton.msg}</PopoverBody>
      </Popover> */}
      <div className="MainParentDiv row">
        <div className="col-6" style={{ padding: '0px' }}>
          <input className="form-control" placeholder="Search Chapter/Content" />
        </div>
        <div className="col-2"></div>
        <div className="col-4 d-flex justify-content-end">
          {/* <button
            className="btn"
            style={{
              borderRadius: '5px',
              background: 'rgba(60, 72, 82, 0.15)',
            }}
            // onClick={toggleBulkUpload}
          >
            <img style={{ color: 'white' }} src={Upload} />
            &nbsp;Upload CSV
          </button> */}
          &nbsp;&nbsp;
          <button
            className="btn"
            style={{
              borderRadius: '5px',
              background: 'rgba(60, 72, 82, 0.15)',
            }}
            onClick={e => {
              e.preventDefault();
              setIfLoading(true)
              downloadReport({ data: attendanceData, setIfLoading })
            }}
            color="success"
          >
            <img src={Export} />&nbsp;Export Report
          </button>
        </div>
      </div>
      <br />
      <div className="row learnersAttendance">
        {attendanceData.length > 0 && (
          <table
            style={{ width: '75%', textAlign: "center" }}
            className="table table-bordered learnersAttendanceTable"
          >
            <thead>
              <tr>
                <th>S.No.</th>
                <th>
                  Chapter Name &nbsp;{' '}
                </th>
                <th>Content Name</th>
                <th>Attendance</th>
              </tr>
            </thead>
            <tbody>
              {attendanceData.map((data, idx) => (
                <tr
                >
                  <td>{idx+1}</td>
                  <td>{data.chapterName}</td>
                  <td>{data.contentName}</td>
                  <td
                    id={data.uid}
                    className={'ifPresent' in data && data['ifPresent'] == true ? "presentTd": "absentTd" }
                  >
                    {'ifPresent' in data
                      ? data['ifPresent'] == true
                        ? 'Present'
                        : 'Absent'
                      : 'Not Marked'}
                    &nbsp;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default LearnerAttendanceDetail
