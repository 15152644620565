import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import SkillTagTooltipItem from '../ManageGradebookAndCertification/PreviewGradebook/SkillTagTooltipItem'

// "https://cdn.pegasus.imarticus.org/feedback/trans_parent_star.svg"
// "https://cdn.pegasus.imarticus.org/feedback/yellow.svg"

export function StartRatingScale(props) {
  const [rating, setRating] = useState(0)
  const [answerIndex, setAnswerIndex] = useState(0)
  const [ratingLength, setRatingLength] = useState(5)
  const [answerSetOptionList, setAnswerSetOptionList] = useState([])

  useEffect(() => {
    if (props && props.answerSetOptionList) {
      setRatingLength(props.answerSetOptionList.length)
      setAnswerSetOptionList([...props.answerSetOptionList])
    }
  }, [])
  let handleSelectRating = (index) => {
    setAnswerIndex(index)
  }
  const star_rating_length = Array.from({ length: ratingLength })
  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-around' }}
      className="feedback-management"
    >
      {star_rating_length.map((_, index) => {
        return (
          <div
            style={{
              borderBottom: index <= answerIndex ? '4px solid green' : 'none',
              margin: '2px',
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'space-around',
            }}
          >
            <div>
              <img
                onClick={() => handleSelectRating(index)}
                src={
                  index > answerIndex
                    ? 'https://cdn.pegasus.imarticus.org/feedback/white-transparent-star.png'
                    : 'https://cdn.pegasus.imarticus.org/feedback/yellow.svg'
                }
                alt="png"
                style={{ width: 64, height: 64, cursor: 'pointer' }}
              />
            </div>
            <br></br>
            <div>
              <p
                style={{
                  textAlign: 'center',
                  color: index <= answerIndex ? 'green' : 'black',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                {' '}
                {answerSetOptionList[index]?.opt_txt ? (
                  answerSetOptionList[index].opt_txt.length > 10 ? (
                    <SkillTagTooltipItem
                      id={'skillnamr1' + index + props.index}
                      text={
                        answerSetOptionList[index]?.opt_txt
                          ? answerSetOptionList[index].opt_txt.substring(0, 7)
                          : '-'
                      }
                      feedback={true}
                      batch={
                        answerSetOptionList[index]
                          ? answerSetOptionList[index].opt_txt
                          : '-'
                      }
                    />
                  ) : (
                    answerSetOptionList[index].opt_txt
                  )
                ) : (
                  '-'
                )}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
