import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { Label, Button, ModalFooter } from 'reactstrap'
import ReschedulingFdbkForms from './ReschedulingFdbkForms.jsx'
import { routes } from '../../config/configs'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { Loader, Segment } from 'semantic-ui-react'

import { Autocomplete, TextField, Checkbox } from '@mui/material'
import {
  startFetchCourse,
  AddFeedbackQuestion,
  startFetchBatchCourse,
  startFetchBatchCourseChapters,
  StartFetchCourseFdbk,
  startFetchSurveyFeedback,
} from '../../actions/courseAction.js'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function ViewSurveyResponses() {
  const cookies = new Cookies()

  const theme = useTheme()
  const [personName, setPersonName] = useState([])
  const [loader, setLoader] = useState(false)

  const [coursePageList, setCoursePageList] = useState([])
  const [selectedCoursePage, setSelectedCoursePage] = useState({})

  const [courseList, setCourseList] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})

  const [chapterList, setChapterList] = useState([])
  const [selectedChapter, setSelectedChapter] = useState({})

  const [feedbackTypeList, setFeedbackTypeList] = useState([])
  const [selectedFeedbackType, setSelectedFeedbackType] = useState('')
  const [FeedbackArrayList, setFeedbackArrayList] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedSurveyFeedback, setSelectedSurveyFeedback] = useState({})
  const [surveyFeedbackList, setSurveyFeedbackList] = useState([])

  const [selectedSurveyFeedbackRoundName, setSelectedSurveyFeedbackRoundName] =
    useState({})

  const [surveyFeedbackRoundList, setSurveyFeedbackRoundList] = useState([])

  const [
    selectedSurveyFeedbackRoundNameMap,
    setSelectedSurveyFeedbackRoundNameMap,
  ] = useState({})

  useEffect(() => {
    ;(async () => {
      setLoader(true)
      let res = await startFetchCourse()
      setCoursePageList([...res.data.courses])
      setLoader(false)
    })()
  }, [])

  let handleSelectCoursePage = async (crs) => {
    setLoader(true)

    console.log(crs)
    setSelectedCoursePage({ ...crs })
    let coursePage = crs
    if (coursePage._id) {
      let data = {
        crs_id: coursePage._id,
        limit: 100,
        skip: 0,
      }
      let batch_list = await startFetchBatchCourse(data)

      if (batch_list) {
        setCourseList([...batch_list.data.slicedCourses])
        setSelectedCourse({})
        setFeedbackArrayList([])
        setColumns([])
      }
      setLoader(false)
    }
  }

  let handleSelectCourse = async (batch) => {
    setLoader(true)

    console.log(batch)
    setSelectedCourse({ ...batch })
    let course = batch

    let survey_feedback_list = await startFetchSurveyFeedback(course)

    if (survey_feedback_list && survey_feedback_list.data) {
      setSurveyFeedbackList([...survey_feedback_list.data.survey_feedback_list])
      setSelectedSurveyFeedbackRoundNameMap({
        ...survey_feedback_list.data.survey_feedback_round_map,
      })
    }

    setLoader(false)
  }

  let handleSelectFeedback = async (fdbk) => {
    setLoader(true)

    setSelectedSurveyFeedback({ ...fdbk })
    setSurveyFeedbackRoundList([
      ...selectedSurveyFeedbackRoundNameMap[fdbk._id],
    ])
    let queryURL =
      routes.BASE_URL_DEVELOPMENT +
      '/cfpanelGeneric/fetch_survey_feedback_student_dump'
    var getData = {
      url: queryURL,
      method: 'POST',
      data: {
        filterCourse: selectedCoursePage._id,
        filterBatch: selectedCourse._id,
        survey_fdbk_id: fdbk._id,
        repKey: 'surveyFeedback',
      },
      headers: {
        'x-access-token': cookies.get('at'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
    axios(getData)
      .then((res) => {
        console.log(res.data.data)
        if (res.data.data.columns) {
          setColumns([...res.data.data.columns])
        } else {
          setColumns([])
        }
        setFeedbackArrayList([...res.data.data.dataForTable])

        setLoader(false)
        console.log(' ================ \n\n')
      })
      .catch((err) => {
        console.error(err)
        setLoader(false)
      })
  }

  let handleSelectFeedbackRound = async (fdbk_round) => {
    setLoader(true)

    setSelectedSurveyFeedbackRoundName({ ...fdbk_round })
 
    let queryURL =
      routes.BASE_URL_DEVELOPMENT +
      '/cfpanelGeneric/fetch_survey_feedback_student_dump'
    var getData = {
      url: queryURL,
      method: 'POST',
      data: {
        filterCourse: selectedCoursePage._id,
        filterBatch: selectedCourse._id,
        survey_fdbk_id: selectedSurveyFeedback._id,
        fdbk_id:fdbk_round.fdbk_id,
        repKey: 'surveyFeedback',
      },
      headers: {
        'x-access-token': cookies.get('at'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
    axios(getData)
      .then((res) => {
        console.log(res.data.data)
        if (res.data.data.columns) {
          setColumns([...res.data.data.columns])
        } else {
          setColumns([])
        }
        setFeedbackArrayList([...res.data.data.dataForTable])

        setLoader(false)
        console.log(' ================ \n\n')
      })
      .catch((err) => {
        console.error(err)
        setLoader(false)
      })
  }

  return (
    <div
      style={{
        backgroundColor: 'rgba(37, 92, 168, 0.05)',
        height: '100vh',
        overflowX: 'hidden',
      }}
      className="feedback-management"
    >
      {!loader && (
        <div>
          <FeedbackDashboard />
          <div className="side-bar-tab-view">
            {/* <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Select Program</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            value={selectedCoursePage.name}
            onChange={handleSelectCoursePage}
            input={
              <OutlinedInput id="select-multiple-chip" label="Select Program" />
            }
            renderValue={(selected) => {
              return selected.name
            }}
            MenuProps={MenuProps}
          >
            {coursePageList.map((coursePage) => (
              <MenuItem
                key={coursePage._id}
                value={coursePage}
                // style={getStyles(name, personName, theme)}
              >
                {coursePage.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedCoursePage && selectedCoursePage._id && (
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Select Batch</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={selectedCourse}
              onChange={handleSelectCourse}
              input={
                <OutlinedInput id="select-multiple-chip" label="Select Batch" />
              }
              renderValue={(selected) => {
                return selected.btch_name
              }}
              MenuProps={MenuProps}
            >
              {courseList.map((course) => (
                <MenuItem key={course._id} value={course}>
                  {course.btch_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )} */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '24px',
              }}
            >
              <div style={{ width: '300px' }}>
                <Autocomplete
                  style={{ width: '300px' }}
                  className="select-course"
                  disableClearable
                  value={
                    selectedCoursePage.name
                      ? selectedCoursePage.name
                      : 'Select a Course'
                  }
                  onChange={(e, crs) => {
                    handleSelectCoursePage(crs)
                  }}
                  getOptionLabel={(course) => {
                    return course.name || course
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  options={coursePageList}
                  disabled={coursePageList.length === 0 ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={coursePageList.length == 0 ? 'Loading...' : ''}
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
              </div>
              <div style={{ width: '300px' }}>
                {selectedCoursePage && selectedCoursePage._id && (
                  <Autocomplete
                    style={{ width: '300px' }}
                    className="select-course"
                    disableClearable
                    value={
                      selectedCourse.btch_name
                        ? selectedCourse.btch_name
                        : 'Select a Batch'
                    }
                    onChange={(e, batch) => {
                      handleSelectCourse(batch)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.btch_name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    options={courseList}
                    disabled={courseList.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label={courseList.length == 0 ? 'Loading...' : ''}
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                )}
              </div>
              <div style={{ width: '300px' }}>
                {selectedCourse && selectedCourse._id && (
                  <Autocomplete
                    style={{ width: '300px' }}
                    className="select-course"
                    disableClearable
                    value={
                      selectedSurveyFeedback.nm
                        ? selectedSurveyFeedback.nm
                        : 'Select a Survey Feedback'
                    }
                    onChange={(e, fdbk) => {
                      handleSelectFeedback(fdbk)
                    }}
                    getOptionLabel={(fdbk) => {
                      return fdbk.nm || fdbk
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    options={surveyFeedbackList}
                    disabled={surveyFeedbackList.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label={
                            surveyFeedbackList.length == 0 ? 'Loading...' : ''
                          }
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                )}
              </div>

              <div style={{ width: '300px' }}>
                {selectedSurveyFeedback &&
                  selectedSurveyFeedback._id &&
                  selectedSurveyFeedback.p_type == 3 && (
                    <Autocomplete
                      style={{ width: '300px' }}
                      className="select-course"
                      disableClearable
                      value={
                        selectedSurveyFeedbackRoundName.name
                          ? selectedSurveyFeedbackRoundName.name
                          : 'Select a Survey Feedback Round'
                      }
                      onChange={(e, fdbk) => {
                        handleSelectFeedbackRound(fdbk)
                      }}
                      getOptionLabel={(fdbk) => {
                        return fdbk.name || fdbk
                      }}
                      getOptionSelected={(option, test) => option.name == test}
                      options={surveyFeedbackRoundList}
                      disabled={
                        surveyFeedbackRoundList.length === 0 ? true : false
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            label={
                              surveyFeedbackRoundList.length == 0
                                ? 'Loading...'
                                : ''
                            }
                            {...params}
                            variant="outlined"
                            fullWidth
                          />
                        )
                      }}
                    />
                  )}
              </div>
            </div>
            <hr></hr>
            <div
              style={{
                width: '100%',
                height: '100%',
                background: 'white',
                borderRadius: 8,
                border: '1px rgba(60, 72, 82, 0.25) solid',
                overflowX: 'auto',
              }}
            >
              <table
                class="table table-bordered"
                style={{ marginBottom: '0rem' }}
              >
                <thead>
                  <tr className="tr-layout">
                    {columns.map((col) => {
                      return (
                        <th
                          scope="col"
                          className="table-header"
                          style={{ textAlign: 'center', minWidth: '130px' }}
                        >
                          {col == 'survey_fdbk_name'
                            ? 'Survey Feedback Name'
                            : col}
                        </th>
                      )
                    })}
                  </tr>
                </thead>

                <tbody>
                  {FeedbackArrayList &&
                    FeedbackArrayList.map((data) => {
                      return (
                        <tr>
                          {Object.keys(data).map((dt, index) => {
                            return (
                              <td className="table-cell">
                                {data[columns[index]]}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  {FeedbackArrayList && FeedbackArrayList.length == 0 && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <img
                          style={{ width: '300px' }}
                          src="https://cdn.pegasus.imarticus.org/feedback/empty.png"
                        ></img>
                      </div>
                      <div></div>
                      <div>
                        <p
                          style={{ paddingTop: '24px', paddingBottom: '24px' }}
                          className="empty-text-header"
                        >
                          It's empty here!
                        </p>
                      </div>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {loader && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default ViewSurveyResponses
