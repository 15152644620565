
import React, { useEffect, useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import './FeedbackDashboard.scss'

function NumericScale(props) {
    const[answerSetOptionList,setAnswerSetOptionList] = useState([])
    
  useEffect(() => {
    if (props && props.answerSetOptionList ) {
      // setRatingLength(props.answerSetOptionList.length)
      setAnswerSetOptionList([...props.answerSetOptionList])
    }
},[])
  return (
    <div className='feedback-management'>
      <FormControl>
        {/* <FormLabel id="demo-radio-buttons-group-label">Numeric Scale</FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Numeric Scale"
          name="radio-buttons-group"
          row
          sx={{ gap: '24px', marginLeft: '4px' }} 
        >
            {answerSetOptionList && answerSetOptionList.map((opt,index)=>{
                return(  <FormControlLabel value={opt.opt_point} control={<Radio />} label={opt.opt_point}/>)

            })}
        
       
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default NumericScale

