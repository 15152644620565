import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import { Link } from 'react-router-dom'
import { history } from '../../index'

function FeedbackDashboard() {
  const [headerName, setHeaderName] = useState('Feedback Management')

  useEffect(() => {
    if (
      window.location.href.includes(
        '/feedback-management/manage-feedback-form/'
      )
    ) {
      setHeaderName('Manage Feedback Forms')
    } else if (
      window.location.href.includes('/feedback-management/manage-scales/')
    ) {
      setHeaderName('Manage Scales')
    } else if (
      window.location.href.includes(
        '/feedback-management/reschedule-feedback-forms/'
      )
    ) {
      setHeaderName('Reschedule Feedback Forms')
    } else if (
      window.location.href.includes(
        '/feedback-management/view-survey-responses/'
      )
    ) {
      setHeaderName('View Survey Responses')
    } else if (
      window.location.href.includes('feedback-management/create-feedback-form')
    ) {
      setHeaderName('Create/Edit Feedback Form')
    }
  }, [])

  return (
    <div className='feedback-management'   >
      <div className="as-fdbk-sidebar">
        <div className="imart-logo">
          {' '}
          <Link to="/" className="as-dl-sidebar-lg ">
            <img
              src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
              className="as-dl-logo-lp"
              alt="home"
              style={{ marginTop: '16px' }}
            />
          </Link>
        </div>
        {window.location.href.includes(
          'feedback-management/create-feedback-form'
        ) && (
            <div className="side-text-tab sidebar-hover" style={{ justifyContent: "space-between" }}>
              <Link
                className="link"
                to={`/feedback-management/manage-feedback-form/`}
              >
                <p className="side-text" style={{ textAlign: 'start' }}>
                  <img src="https://cdn.pegasus.imarticus.org/Gradebook/chevron-up.svg" />  Back&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>{' '}
              </Link>
            </div>
          )}

        {!window.location.href.includes(
          'feedback-management/create-feedback-form'
        ) && (
            <>
              <div className="side-text-tab sidebar-hover" >
                <Link className="link" to={`/head`}>
                  {/* <p className="side-text">  <img src="https://cdn.pegasus.imarticus.org/Gradebook/chevron-up.svg" /> Back</p>{' '} */}
                  <p className="side-text" style={{ textAlign: 'start',verticalAlign:"center" }}>
                    <img src="https://cdn.pegasus.imarticus.org/Gradebook/chevron-up.svg" /> Back&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>{' '}
                </Link>
              </div>
              <div
                className={
                  window.location.href.includes(
                    '/feedback-management/manage-feedback-form/'
                  )
                    ? 'selected-tab'
                    : 'side-text-tab sidebar-hover'
                }
              >
                <Link
                  to={`/feedback-management/manage-feedback-form/`}
                  className="link side-text"
                >
                  Manage Feedback Forms &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <p className="side-text">Manage Feedback Forms</p>{' '} 31*/}
                </Link>
              </div>
              <div
                className={
                  window.location.href.includes(
                    '/feedback-management/manage-scales/'
                  )
                    ? 'selected-tab'
                    : 'side-text-tab sidebar-hover'
                }
              >
                <Link to={`/feedback-management/manage-scales/`} className="link">
                  <p className="side-text">Manage Scales&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>{' '}
                </Link>
              </div>
              <div
                className={
                  window.location.href.includes(
                    '/feedback-management/reschedule-feedback-forms/'
                  )
                    ? 'selected-tab'
                    : 'side-text-tab sidebar-hover'
                }
              >
                <Link
                  to={`/feedback-management/reschedule-feedback-forms/`}
                  className="link"
                >
                  <p className="side-text">Reschedule Feedback Forms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>{' '}
                </Link>
              </div>
              <div
                className={
                  window.location.href.includes(
                    '/feedback-management/view-survey-responses/'
                  )
                    ? 'selected-tab'
                    : 'side-text-tab sidebar-hover'
                }
              >
                <Link
                  to={`/feedback-management/view-survey-responses/`}
                  className="link"
                // onClick={() => {
                //   toggle(3)
                // }}
                >
                  <p className="side-text">View Survey Responses&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>{' '}
                </Link>
              </div>
              <div
                className={
                  window.location.href.includes(
                    '/learnermonitoringpanel/reports/combinedFeedbackReport'
                  )
                    ? 'selected-tab'
                    : 'side-text-tab sidebar-hover'
                }
              >
                <Link
                  to={`/learnermonitoringpanel/reports/combinedFeedbackReport`}
                  className="link"
                  target="_blank"
                >
                  <p className="side-text">View Feedback Analysis Reports &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>{' '}
                </Link>
              </div>
            </>
          )}
      </div>
      <div className="fdbk-dashboard ">
        <div className="dashboard-header">
          <div className="header-text"> {headerName}</div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackDashboard
