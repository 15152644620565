import React from "react"
import { convertToHours, epochToDate, epochToTime } from "../ScheduleManagementHelper"
import { Button } from "reactstrap"
import NoLogs from "../../ChangeStreamManagement/NoLogs.svg"

const Cancelled = ({metricsData, page, isLoading, setIsLoading, classMap, lectures, chapMap}) => {
    console.log("Cancelled :: ", {metricsData, page, isLoading, setIsLoading, classMap, lectures})
    return <>
        <br/>
        {lectures && lectures.length > 0 ?
        <table className="table facultyDashboardTable">
            <thead>
                <tr>
                    <th>S.No.</th>
                    <th>Chapter Name</th>
                    <th>Topic Name</th>
                    <th>Number of Hours</th>
                    <th>Schedule Date</th>
                    <th>Time</th>
                    <th>Classroom</th>
                    <th>Remarks</th>
                </tr>
            </thead>
            <tbody>
                {lectures && lectures.length > 0 && lectures.map((l,idx) => 
                    <tr>
                        <td>{idx+1}</td>
                        <td style={{wordWrap: "break-word"}} className="LiveLecName">{chapMap[l.chpid]?.nm || "-"}</td>
                        <td style={{wordWrap: "break-word"}} className="LiveLecName">{l.nm}</td>
                        <td>{convertToHours(l.tot_tim)}</td>
                        <td>{epochToDate(l.stm)}</td>
                        <td>{epochToTime(l.stm)}</td>
                        <td>{l.cl_id && classMap[l.cl_id]?.name || "-"}</td>
                        <td>{l.remarks}</td>
                    </tr>
                )}
            </tbody>
        </table>
        : 
        <div style={{display: "flex", flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
            <img src={NoLogs} width="400px"/>
            <br/>
            <h4>Its empty here!</h4>
            <p>Select the Course and Batch, and we will fetch you your lectures!</p>
        </div>
    }
    </>
}
export default Cancelled