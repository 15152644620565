import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { Label, Button, ModalFooter } from 'reactstrap'
import { Loader, Segment } from 'semantic-ui-react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import EmojiRating from './EmojiRating.jsx'
import { StartRatingScale } from './StartRatingScale.jsx'
import NumericRatingScale from './NumericRatingScale.jsx'
import EmojiScaleRating from './EmojiScaleRating.jsx'
import './FeedbackDashboard.scss'

import NumericScale from './NumericScale.jsx'

import QualityScale from './QualityScale.jsx'

import {

  AddFeedbackScale,
  EditFeedbackScale

} from '../../actions/courseAction.js'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

function AddAndEditFeedbackScale({ modalOpen, handleModal, edit, answerSet, answer_template, isDefault }) {
  const [feedback, setFeedback] = useState({})
  const [loading, setLoading] = useState(false)
  const [answerTemplateList, setAnswerTemplateList] = useState([])
  const [templateName, setTemplateName] = useState()
  const [templateId, setTemplateId] = useState('')
  const [editFdbkScale, setEditFdbkScale] = useState(false)
  const [answerSetId, setAnswerSetId] = useState()

  console.log({ edit })


  const [answerSetOptionList, setAnswerSetOptionList] = useState([
    { opt_txt: 'Awful', opt_point: 1 },
    { opt_txt: 'Bad', opt_point: 2 },
    { opt_txt: 'Okay', opt_point: 3 },
    { opt_txt: 'Good', opt_point: 4 },
    { opt_txt: 'Excellent', opt_point: 5 },
  ])

  const [feedbackScaleName, setFeedbackScaleName] = useState('')


  useEffect(() => {
    ; (async () => {
      setLoading(true)
      // await getDynamicField()
      // await getFeedbackScale()
      // console.log(answer_template)
      console.log(answerSet)

      if (answerSet && answerSet.ansoptions) {
        setAnswerSetOptionList([...answerSet.ansoptions])

        let temp = answer_template.filter((temp) => temp._id == answerSet.template_id)
        console.log({ temp })
        if (temp[0]) {
          setTemplateName(temp[0].template_name)

        }
        setTemplateId(answerSet.template_id)

      }
      if (answerSet && answerSet.ans_set_name) {
        setFeedbackScaleName(answerSet.ans_set_name)
        setAnswerSetId(answerSet._id)

      }
      setLoading(false)



    })()
  }, [])

  useEffect(() => {

    setAnswerTemplateList([...answer_template])
    setEditFdbkScale(edit)
  }, [])

  let handleFeedbackTemplate = (val) => {

    console.log({ val })
    let temp = answerTemplateList.filter((temp) => temp._id == val)
    console.log({ temp })
    if (temp[0]) {
      setTemplateName(temp[0].template_name)

    }
    setTemplateId(val)
  }



  let handleSave = async () => {
    setLoading(true)
    let feedback_scale_name = ''
    if (feedbackScaleName) {
      feedback_scale_name = feedbackScaleName.trim()
    }

    if (!feedback_scale_name || feedback_scale_name == "" || feedback_scale_name == "." || templateId == '') {
      window.alert('Invalid Details')
      setLoading(false)
      return
    }
    let res
    if (!editFdbkScale) {
      res = await AddFeedbackScale(
        answerSetOptionList,
        templateId,
        feedbackScaleName,
      )
    } else {
      res = await EditFeedbackScale(
        answerSetOptionList,
        templateId,
        feedbackScaleName,
        answerSetId,)

    }

    if ('statusCode' in res && res.statusCode == 403) {
      window.alert(res.error)
      setLoading(false)
      return
    }
    if (res.success) {
      window.alert('Saved Successfully')
      setLoading(false)
    }
    if (!res.success) {
      if (res.message) {
        window.alert(res.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }


    setLoading(false)
    handleModal()
  }


  return (
    <div className='feedback-management'>
      {/* {!editFdbkScale && <Button
        color="success"
        onClick={() => {
          handleModal()
        }}
      >
        + Create New Scale Set
      </Button>}

      {editFdbkScale && <Button
        color="success"
        onClick={() => {
          handleModal()
        }}
      >
        {' '}
        <EditOutlinedIcon />
      </Button>} */}

      {!loading && (
        <Modal
          style={{
            height: 'fit-content',
            margin: 'auto',
            marginTop: 'auto',
            width: '573px',
          }}
          open={modalOpen}
          className='feedback-management'

        >
          <Modal.Header>{editFdbkScale ? 'Edit Scale' : 'Add Scale'}</Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}>
            <div className="form-group" style={{ marginLeft: "24px", marginRight: "24px" }}>
              <div className=" add-ques-sub-header">
                <p style={{ width: '40%' }}>Scale Name</p>
              </div>
              <TextField
                size="medium"
                id="outlined-basic"
                label="Enter the Scale Name"
                variant="outlined"
                value={feedbackScaleName}
                onChange={(e) => {
                  setFeedbackScaleName(e.target.value)
                }}
                style={{ width: '100%' }}
                disabled={isDefault}
              />
              <br></br>
              <br></br>
              {templateName && templateName != 'Numeric Scale' && templateName != 'Numeric rating Scale' && <div>
                <div className="option-list add-ques-sub-header">
                  <p style={{ width: '40%' }}>Options</p>
                  <p style={{ width: '40%' }}>
                    Points Allocated ( Between 1-5)
                  </p>
                </div>

                {answerSetOptionList &&
                  answerSetOptionList.map((ans, index) => {
                    return (
                      <div key={index}>
                        <div className="option-list">
                          <TextField
                            disabled={isDefault}
                            size="medium"
                            className="select-course"
                            id="outlined-basic"
                            label="Options"
                            variant="outlined"
                            value={answerSetOptionList[index].opt_txt}
                            onChange={(e) => {
                              let answerSetOptionList_temp = [
                                ...answerSetOptionList,
                              ]
                              answerSetOptionList_temp[index].opt_txt =
                                e.target.value
                              setAnswerSetOptionList([
                                ...answerSetOptionList_temp,
                              ])
                            }}
                            style={{ width: '40%' }}
                          />
                          <TextField
                            disabled={true}
                            size="medium"
                            className="select-course"
                            id="outlined-basic"
                            label="Points Allocated"
                            variant="outlined"
                            type="number"
                            value={answerSetOptionList[index].opt_point}
                            onChange={(e) => {
                              let answerSetOptionList_temp = [
                                ...answerSetOptionList,
                              ]
                              if (!isNaN(Number(e.target.value))) {
                                answerSetOptionList_temp[index].opt_point = Number(
                                  e.target.value
                                )
                              }
                              setAnswerSetOptionList([
                                ...answerSetOptionList_temp,
                              ])
                            }}
                            style={{ width: '40%' }}
                          />
                        </div>
                        <br></br>
                        {/* <br></br> */}
                      </div>
                    )
                  })}
              </div>}
            </div>
            <div style={{ marginLeft: "24px", marginRight: "24px" }}>
              <div className=" add-ques-sub-header">
                <p style={{ width: '60%' }}>Select Feedback Template</p>
              </div>
              <select
                disabled={isDefault}

                className="select-course"
                id="overrideSelectCourse"
                onChange={(e) => handleFeedbackTemplate(e.target.value)}
                style={{ width: '100%' }}

              >
                <option className="dropdown-item " hidden>
                  {templateName ? templateName : 'Select Feedback Template'}
                </option>
                {answerTemplateList.map((temp, key) => (
                  <option key={key} className="dropdown-item " value={temp._id}>
                    {temp.template_name}
                  </option>
                ))}
              </select>
            </div>
            <div className=" add-ques-sub-header" style={{ marginLeft: "24px", marginRight: "24px" }}>
              <p style={{ width: '40%' }}>Preview</p>
              {templateName == 'Numeric rating Scale' && <NumericRatingScale answerSetOptionList={answerSetOptionList} />}
              {templateName == 'Numeric Scale' && <NumericScale answerSetOptionList={answerSetOptionList} />}
              {templateName == 'Star rating Scale' && <StartRatingScale answerSetOptionList={answerSetOptionList} />}
              {templateName == 'Emoji  rating Scale' && <EmojiScaleRating answerSetOptionList={answerSetOptionList} />}
              {templateName == 'Quality Scale' && <QualityScale answerSetOptionList={answerSetOptionList} />}
              <br></br>
            </div>

            {(templateName == 'Numeric rating Scale' || templateName == 'Numeric Scale') && (
              <FormLabel style={{ color: "black", marginLeft: "24px", marginRight: "24px" }}>
                *The numeric scale and numeric rating scale cannot be customized.
              </FormLabel>
            )}
          </Modal.Content>
          <ModalFooter>

            <Button style={{ backgroundColor: '#055646' }}
              disabled={isDefault}

              onClick={handleSave}>

              Save
            </Button>
            <Button
              color="danger"
              onClick={(e) => {
                handleModal()
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default AddAndEditFeedbackScale
