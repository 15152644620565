import { useEffect, useState } from "react"
import { convertToHours, downloadInvoice, epochToDate, epochToTime } from "../ScheduleManagementHelper"
import { Button, Popover, PopoverBody, PopoverItem, PopoverHeader, } from "reactstrap"
import NoLogs from "../../ChangeStreamManagement/NoLogs.svg"
import Download from "../images/download.svg"

const Completed = ({metricsData, page, classMap, lectures, chapMap, faculty, selectedBatch, selectedCourse, filters}) => {
    console.log("COMPLETED :: ", {metricsData, page, classMap, lectures, faculty})
    const [topicsForFilter, setTopicsForFilter] = useState([])
    const [popoverOpen, setPopoverOpen] = useState(-1); 
    const [popoverOpenIbutton, setIButtonPopoverOpen] = useState(false)
    const [lecturesToShow, setLecturesToShow] = useState(lectures)

    useEffect(() => {
        setTopicsForFilter([...new Set(lectures.map(l => l.actual_topicname))]);
        setLecturesToShow(lectures);
    }, [lectures]);

    const handleClickOnFilter = (e) => {
        const selectedTopic = e.target.value;
        setLecturesToShow(selectedTopic === "--" ? lectures : lectures.filter(l => l.actual_topicname === selectedTopic));
    };
    return <>
    <br/>
        <div style={{float: "right", display: "flex"}}>
            <select style={{marginTop: "15px", marginBottom: "15px", height: "38px"}} className="form-control" onChange={handleClickOnFilter}>
                <option value={"--"}><i>Select Topic</i></option>
                {topicsForFilter.map(tf =><option value={tf}>
                    {tf}
                </option>)}
            </select> &nbsp;&nbsp;&nbsp;
            <Button 
                style={{float: "right", borderRadius: "5px", border: "1px solid #055646", marginTop: "15px", marginBottom: "15px", background: "transparent", color: "#055646", fontWeight: "600"}} 
                onClick={e => {
                    e.preventDefault();
                    downloadInvoice({ crsId: selectedCourse._id, from: filters.from, to: filters.to, cid: selectedBatch._id, page })}
                }
            ><img src={Download} />&nbsp;Download Report</Button>
        </div>
        <br/>
        <br/>
        {lecturesToShow && lecturesToShow.length > 0 ?
        <table className="table table-responsive facultyDashboardTable">
            <thead>
                <tr>
                    <th>S.No.</th>
                    <th>Chapter Name</th>
                    <th>Topic Name</th>
                    <th>Number of Hours</th>
                    <th>Schedule Date</th>
                    <th>Time</th>
                    <th>Classroom</th>
                    <th>Actual Topic Name</th>
                    <th>Actual Duration</th>
                    <th>Income per class <span id={"popoverIButton"} className={"myPopoverBtn"} onClick={e => setIButtonPopoverOpen(!popoverOpenIbutton) }>i</span></th>
                    <Popover 
                        placement="bottom" 
                        target={"popoverIButton"} 
                        isOpen={popoverOpenIbutton}
                        toggle={(e) => setIButtonPopoverOpen(!popoverOpenIbutton)}>
                        {/* <PopoverHeader>Income breakup</PopoverHeader>  */}
                        <PopoverBody>
                            Click on the amount to view the payout breakup
                        </PopoverBody> 
                    </Popover>
                </tr>
            </thead>
            <tbody>
                {lectures && lectures.length > 0 && lectures.map((l,idx) => 
                    <tr>
                        <td>{idx+1}</td>
                        <td>{chapMap[l.chpid]?.nm || "-"}</td>
                        <td>{l?.nm || "-"}</td>
                        <td>{convertToHours(l.tot_tim)}</td>
                        <td>{epochToDate(l.stm)}</td>
                        <td>{epochToTime(l.stm)}</td>
                        <td>{l.cl_id && classMap[l.cl_id]?.name || "-"}</td>
                        <td className="yellowRows">{l.actual_topicname}</td>
                        <td className="yellowRows">{convertToHours(l.actual_time)}</td>
                        <td className="yellowRows" style={{cursor: "pointer"}}>
                        <span id={"popover"+idx} onClick={e => setPopoverOpen(idx) }>₹{l.payout?.toFixed(2)}</span> 
                        <Popover 
                            className="facultyInvoicePopover"
                            placement="bottom" 
                            target={"popover"+idx} 
                            isOpen={idx === popoverOpen}
                            toggle={(e) => setPopoverOpen(-1)}>
                            <PopoverHeader>Income breakup</PopoverHeader> 
                            <PopoverBody>
                                <div className="row">
                                    <div className="col-8">Income per hour</div>
                                    <div className="col-4" style={{color: "green", textAlign: "end", fontWeight: "600"}}>₹{l.perHourPrice}</div>
                                </div>
                                <div className="row">
                                    <div className="col-8">Number of hours</div>
                                    <div className="col-4" style={{color: "green", textAlign: "end", fontWeight: "600"}}>{convertToHours(l.tot_tim)}</div>
                                </div>
                                <div className="row">
                                    <div className="col-8">Actual hours</div>
                                    <div className="col-4" style={{color: "green", textAlign: "end", fontWeight: "600"}}>{convertToHours(l.actual_time)}</div>
                                </div>
                                <div className="row">
                                    <div className="col-8">Difference in hours</div>
                                    <div className="col-4" style={{color: "green", textAlign: "end", fontWeight: "600"}}>{convertToHours(l.actual_time - l.tot_tim)}</div>
                                </div>
                                <hr style={{width: "90%"}} />
                                <div className="row">
                                    <div className="col-8">Total Income</div>
                                    <div className="col-4" style={{color: "green", textAlign: "end", fontWeight: "600"}}>{l.payout}</div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-4"></div> */}
                                    <div className="col-12 text-end" style={{color: "green"}}><small>(Income per hour * Actual hours) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small></div>
                                </div>
                            </PopoverBody> 
                        </Popover> 
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
        : 
        <div style={{display: "flex", flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
            <img src={NoLogs} width="400px"/>
            <br/>
            <h4>Its empty here!</h4>
            <p>Select the Course and Batch, and we will fetch you your lectures!</p>
        </div>
    }
    </>
}
export default Completed


const convertHoursToNumber = num => parseInt(num.split(":")[0])